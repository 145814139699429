import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

/*
    * No Props available
*/
class Footer extends Component {

    render() {
        return (
            <Container fluid="true">
                <Container className="footer">
                    <Row>
                        <Col className="text-center"><h5><a href="/privacy-policy">PRIVACY POLICY</a></h5></Col>
                        <Col className="text-center"><h5><a href="/accessibility">ACCESSIBILITY</a></h5></Col>
                        <Col className="text-center"><h5><a href="/site-map">SITE MAP</a></h5></Col>
                    </Row>


                    <Row>
                      <Col>
                        <p className="text-center mt-5"><small>

                          <Switch>
                            <Route path="/contact">

                                20/20 Productions Europe Ltd - Registered Office: 181 Pleasance, Edinburgh, EH8 9RU, UK. Registered in Scotland No: 127480<br/>
                                Registered USA address: 20/20 Productions, 350 Townsend Street, Suite 425, San Francisco, CA 94107, USA <br/>

                              </Route>
                            </Switch>

                            &copy; Copyright 2022. All rights reserved.
                          </small></p>
                        </Col>
                      </Row>

                </Container>
            </Container>
        );
    }
}
export default Footer;
