//Import modules
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import Logo from '../../assets/images/2020_logo.svg';
import LogoAlt from '../../assets/images/2020_logo_navy.svg';
import { Navbar, Nav } from 'react-bootstrap';
import SideNav, {MenuIcon} from 'react-simple-sidenav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';


class NavBar extends Component {

    constructor() {
        super();

        this.state = {
          scrolled: false,
          scrollPixelsY: 0,
          page: '',
        }
    }

    componentDidMount() {
      window.addEventListener('scroll', this.handleScroll);
      this.setState({page: window.location}, () =>{
      });

    };

    componentDidUpdate(prevState){
      if (this.state.page !== window.location) {
        this.setState({page: window.location});
      }
    }

    componentWillUnmount() {
      window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
      this.setState({
        scrollPixelsY: window.pageYOffset
      });


      if(this.state.scrollPixelsY > 100) {
        this.setState({scrolled: true});
      }
      else {
        this.setState({scrolled: false});
      }


      if(this.state.scrolled) {
        document.querySelector('.mainNavbar').classList.remove('navbar-light');
        document.querySelector('.mainNavbar').classList.add('navbar-dark');
      }
      else{
        document.querySelector('.mainNavbar').classList.remove('navbar-dark');
        document.querySelector('.mainNavbar').classList.add('navbar-light');
      }
    };

    render() {

      const item1 = ( <Nav.Item key={1}>
                      <NavLink  className="navLinks nav-link" to="/what-we-do" onClick={() => this.setState({showNav: false})}>WHAT WE DO</NavLink>
                      </Nav.Item>);

      const item2 = (    <Nav.Item key={2}  >
                    <NavLink className="navLinks nav-link" to="/projects" onClick={() => this.setState({showNav: false})}>PROJECTS</NavLink>
                  </Nav.Item>);

      const item3 = (   <Nav.Item key={3}>
                        <NavLink  className="navLinks nav-link" to="/about" onClick={() => this.setState({showNav: false})}>ABOUT</NavLink>
                        </Nav.Item>);

      const item4 = ( <Nav.Item key={5}>
                        <NavLink  className="navLinks nav-link contact-link" to="/contact" onClick={() => this.setState({showNav: false})}>CONTACT</NavLink>
                        </Nav.Item>);

      const logo3 = ( <Nav.Item key={6}>
                        <NavLink  to="/" onClick={() => this.setState({showNav: false})}>
                          <img
                            src={Logo}
                            width="80px"
                            id="logo"
                            alt="20/20 Productions"
                            className="logo3"
                          />
                        </NavLink>
                      </Nav.Item>
                    );

      const text = ('');

      const close = (<FontAwesomeIcon icon={faTimes} key={11} className="closeSubNav" onClick={() => this.setState({showNav: false})} />);

        return (
          <div>
              <Navbar fixed="top" collapseOnSelect expand="md" bg="transparent" variant="light" className="mainNavbar">

                <Navbar.Brand href="/">
                    <img
                        src={Logo}
                        width="80px"
                        id="logo"
                        alt="20/20 Productions"
                    />
                    <img
                        src={LogoAlt}
                        width="80px"
                        id="logoAlt"
                        alt="20/20 Productions"
                    />

                    <div className="companyName" >20/20 Productions</div>
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">

                  <Nav defaultActiveKey="/what-we-do" className="insideNav">
                    {[
                      item1,
                      item2,
                      item3,
                      item4
                    ]}
                  </Nav>
                </Navbar.Collapse>
              </Navbar>

              <div className="SideNav">
                <MenuIcon onClick={() => this.setState({showNav: true})} className="menuIcon" style={{display: this.state.showNav ? 'none' : 'block' }}/>

                <SideNav className="sideNavInside"
                  showNav        =  {this.state.showNav}
                  onHideNav      =  {() => this.setState({showNav: false})}
                  title          =  {[logo3, text, close]}
                  items          =  {[ item1, item2, item3, item4 ]}
                  titleStyle     =  {{backgroundColor: '',height: '80px'}}
                  itemStyle      =  {{backgroundColor: ''}}
                  navStyle       =  {{backgroundColor: 'rgba(45, 45, 45, 0.9)', width: '100%'}}
                  itemHoverStyle =  {{}}
                  openFromRight  =  {true}
                  />
              </div>

          </div>
        )
    }
}

export default withRouter(NavBar);
