/* eslint-disable jsx-a11y/no-access-key */
//Import modules
import React, { Component, Suspense, lazy } from 'react';
import { Spinner } from 'react-bootstrap';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import ReactGA from 'react-ga';

// components
import NavBar from './components/navbar/navbar';
import Footer from './components/footer/footer';
import ScrollToTop from './components/scroll-to-top/scroll-to-top';
// pages
// import Projects from './pages/projects/projects';
// import Project from './pages/projects/project';
// import Articles from './pages/articles/articles';
// import Posts from './pages/articles/posts';
// import About from './pages/about/about';
// import WhatWeDo from './pages/what-we-do/what-we-do';
// import WhatWeDoEvents from './pages/our-services/eventproduction';
// import WhatWeDoFilm from './pages/our-services/film';
// import WhatWeDoDesign from './pages/our-services/design';
// import WhatWeDoWeb from './pages/our-services/web';
// import WhatWeDoMotion from './pages/our-services/motion';
// import WhatWeDoDigital from './pages/our-services/digital';
// import Contact from './pages/contact/contact';
// import Sitemap from './pages/site-map/sitemap';
// import Accessibility from './pages/accessibility/accessibility';
// import Careers from './pages/careers/careers';
// import Privacy from './pages/privacy-policy/privacypolicy';
// import LinkTree from './pages/link-tree/linktree';
// import PageNotFound from './pages/pageNotFound';
// import WebDesignEdinburgh from './pages/landing-pages/web-design-edinburgh';
// import ProductionCompaniesEdinburgh from './pages/landing-pages/production-companies-edinburgh';
// import DigitalSignageContentProviders from './pages/landing-pages/digital-signage-content-providers';
// import CreativeAgencyEdinburgh from './pages/landing-pages/creative-agency-edinburgh';
// import CorporateEventProduction from './pages/landing-pages/corporate-event-production';
// import AnimationCompaniesEdinburgh from './pages/landing-pages/animation-companies-edinburgh';
// import EventProductionCompanies from './pages/landing-pages/event-production-companies';
// import EventProductionEdinburgh from './pages/landing-pages/event-production-edinburgh';
// import SFEventProductionCompanies from './pages/landing-pages/san-francisco-event-production-companies';
// import SFProductionCompanies from './pages/landing-pages/san-francisco-production-companies';
// import VideoProductionEdinburgh from './pages/landing-pages/video-production-edinburgh';
// import DigitalSignageSolutionsCompanies from './pages/landing-pages/digital-signage-solutions-companies';



// css
import './App.scss';
// xml
import SitemapXML from './sitemap.xml';

class App extends Component {

    constructor() {
        super();

        this.cookieURL = 'localhost';
        this.dataBrowser = [
            {string: navigator.userAgent, subString: "Edge", identity: "MS Edge"},
            {string: navigator.userAgent, subString: "MSIE", identity: "Explorer"},
            {string: navigator.userAgent, subString: "Trident", identity: "Explorer"},
            {string: navigator.userAgent, subString: "Firefox", identity: "Firefox"},
            {string: navigator.userAgent, subString: "Opera", identity: "Opera"},
            {string: navigator.userAgent, subString: "OPR", identity: "Opera"},
            {string: navigator.userAgent, subString: "Chrome", identity: "Chrome"},
            {string: navigator.userAgent, subString: "Safari", identity: "Safari"},
            {string: navigator.userAgent, subString: "Safari", identity: "Safari"}
        ]
    }

    componentDidMount() {
        this.checkCookieCompliance();
        this.detectBrowser();
    };

    searchString(data) {
        for (var i = 0; i < data.length; i++) {
            var dataString = data[i].string;
            this.versionSearchString = data[i].subString;

            if (dataString.indexOf(data[i].subString) !== -1) {
                return data[i].identity;
            }
        }
    }
    searchVersion(dataString) {
        var index = dataString.indexOf(this.versionSearchString)
        if (index === -1) {
            return;
        }

        var rv = dataString.indexOf("rv:");
        if (this.versionSearchString === "Trident" && rv !== -1) {
            return parseFloat(dataString.substring(rv + 3))
        } else {
            return parseFloat(dataString.substring(index + this.versionSearchString.length + 1))
        }
    }

    detectBrowser () {
        this.browser = this.searchString(this.dataBrowser) || "Other"
        this.version = this.searchVersion(navigator.userAgent) || this.searchVersion(navigator.appVersion) || "Unknown"
        // console.log(window.Modernizr);
        // console.log("You are using " + this.browser + " version " + this.version)
        if (this.browser === 'Explorer'){
            document.querySelector('#root').classList.add('ie-class');
        }
        if (this.browser === 'Safari'){
            document.querySelector('#root').classList.add('safari-class');
        }
    }

    checkCookieCompliance(){
        //Set to dismiss notification
        if (document.cookie.split(';').filter((item) => {
            return item.includes('consentCookie=true')
        }).length) {
            this.onAccept();
        }
        //Set to deny cookies
        else if (document.cookie.split(';').filter((item) => {
            return item.includes('consentCookie=false')
        }).length) {
            this.onDelete();
        }
        //Not set - running
        else{
            // this.onAccept();
        }
    }

    deleteCookie(name, domain) {
        document.cookie = name+"=;expires=Mon, 01 Jan 2016 00:00:00 UTC; path=/; domain="+domain+";";
    }

    onAccept(){
        //Run Google Analytics
        ReactGA.initialize('UA-7464175-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
        ReactGA.set({ anonymizeIp: true });
        //Run LinkedIn
        var _linkedin_data_partner_id = "320266";
        (function(){var s = document.getElementsByTagName("script")[0];
        var b = document.createElement("script");
        b.type = "text/javascript";b.async = true;
        b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
        s.parentNode.insertBefore(b, s);})();
    }

    onDelete(){
        this.deleteCookie('_ga', this.cookieURL);
        this.deleteCookie('_gat', this.cookieURL);
        this.deleteCookie('_gid', this.cookieURL);
        this.deleteCookie('bcookie', '.linkedin.com');
        this.deleteCookie('lang', '.linkedin.com');
        this.deleteCookie('lidc', '.linkedin.com');
    }


    render() {
        //import pages with lazy code-splitting
        const Projects = lazy( () => import('./pages/projects/projects') ) ;
        const About = lazy( () => import('./pages/about/about') ) ;
        const WhatWeDo = lazy( () => import('./pages/what-we-do/what-we-do') ) ;
        const WhatWeDoEvents = lazy( () => import('./pages/our-services/eventproduction') ) ;
        const WhatWeDoFilm = lazy( () => import('./pages/our-services/film') ) ;
        const WhatWeDoDesign = lazy( () => import('./pages/our-services/design') ) ;
        const WhatWeDoMotion = lazy( () => import('./pages/our-services/motion') ) ;
        const Contact = lazy( () => import('./pages/contact/contact') ) ;
        const Sitemap = lazy( () => import('./pages/site-map/sitemap') ) ;
        const Accessibility = lazy( () => import('./pages/accessibility/accessibility') ) ;
        const Careers = lazy( () => import('./pages/careers/careers') ) ;
        const Privacy = lazy( () => import('./pages/privacy-policy/privacypolicy') ) ;
        const LinkTree = lazy( () => import('./pages/link-tree/linktree') ) ;
        const PageNotFound = lazy( () => import('./pages/pageNotFound') ) ;
        const ProductionCompaniesEdinburgh = lazy( () => import('./pages/landing-pages/production-companies-edinburgh') ) ;
        const DigitalSignageContentProviders = lazy( () => import('./pages/landing-pages/digital-signage-content-providers') ) ;
        const CreativeAgencyEdinburgh = lazy( () => import('./pages/landing-pages/creative-agency-edinburgh') ) ;
        const CorporateEventProduction = lazy( () => import('./pages/landing-pages/corporate-event-production') ) ;
        const AnimationCompaniesEdinburgh = lazy( () => import('./pages/landing-pages/animation-companies-edinburgh') ) ;
        const EventProductionCompanies = lazy( () => import('./pages/landing-pages/event-production-companies') ) ;
        const EventProductionEdinburgh = lazy( () => import('./pages/landing-pages/event-production-edinburgh') ) ;
        const SFEventProductionCompanies = lazy( () => import('./pages/landing-pages/san-francisco-event-production-companies') ) ;
        const SFProductionCompanies = lazy( () => import('./pages/landing-pages/san-francisco-production-companies') ) ;
        const VideoProductionEdinburgh = lazy( () => import('./pages/landing-pages/video-production-edinburgh') ) ;
        const DigitalSignageSolutionsCompanies = lazy( () => import('./pages/landing-pages/digital-signage-solutions-companies') ) ;
        const UsaPayments = lazy( () => import('./pages/usapayments/usapayments') ) ;


        return (


            <Router onUpdate={() => window.scrollTo(0, 0)}>
            <ScrollToTop></ScrollToTop>
                <div>
                    <NavBar></NavBar>
                    <Suspense fallback={<div className="content-start-sub-gap-article" ><Spinner animation="grow" variant="danger" /> <Spinner animation="grow" variant="warning" /><Spinner animation="grow" variant="success" /></div>}>
                        <Switch>
                            {/* Handle redirects from prevoius site */}
                            <Redirect from='/ourservices.html' to='/what-we-do' />
                                <Redirect from='/creative.html' to='/our-services/design' />
                                <Redirect from='/digital.html' to='/our-services/digital' />
                                <Redirect from='/film.html' to='/our-services/film' />
                                <Redirect from='/eventproduction.html' to='/our-services/event-production' />
                            <Redirect from='/ourwork.html' to='/projects' />
                                <Redirect from='/posts/work-redarrowgreatbritaintour.html' to='/project/red-arrows-north-american-tour' />
                                <Redirect from='/posts/work-edringtonsupplychain.html' to='/project/supply-chain-video' />
                                <Redirect from='/posts/ourwork-tbrecruitmentvt.html' to='/project/software-engineering-recruitment-video' />
                                <Redirect from='/posts/ourwork-napierrecruitmentvt.html' to='/project/recruitment-campaign-video' />
                                <Redirect from='/posts/work-eventit2019.html' to='/project/eventit-2019' />
                                <Redirect from='/posts/work-gpca19.html' to='/404' />
                                <Redirect from='/posts/work-cipdace18.html' to='/project/annual-conference-exhibition' />
                                <Redirect from='/posts/work-edinburghrugbyheadshots.html' to='/404' />
                                <Redirect from='/posts/work-cyreniansvt.html' to='/what-is-homelessness' />
                                <Redirect from='/posts/ourwork-slallcolleague18.html' to='/all-colleague-event' />
                                <Redirect from='/posts/work-falkirkwheellaunch.html' to='/project/the-falkirk-wheel-were-reinventing-the-wheel' />
                                <Redirect from='/posts/work-carnegieuktrust.html' to='/project/carnegie-trust-campaign' />
                            <Redirect from='/theteam.html' to='/about' />
                            <Redirect from='/innovation.html' to='/what-we-do' />
                            <Redirect from='/thinktank.html' to='/articles' />
                            <Redirect from='/latestnews.html' to='/articles' />
                            <Redirect from='/contact.html' to='/contact' />
                            <Redirect from='/ouroffices.html' to='/contact' />
                                <Redirect from='/edinburghoffice.html' to='/contact' />
                                <Redirect from='/sanfranciscooffice.html' to='/contact' />
                                <Redirect from='/dubaioffice.html' to='/contact' />
                            <Redirect from='/ourprivacypolicy.html' to='/privacy-policy' />
                            <Redirect from='/accessibility.html' to='/accessibility' />
                            <Redirect from='/showreel.html' to='/projects' />
                            <Redirect from='/usapayments.html' to='/usa-payments' />
                            {/* landing page campaigns */}
                            <Redirect from='/digitalsignagesolutionscompanies.html' to='/campaign/digital-signage-solutions-companies' />
                            <Redirect from='/digitalsignagecontentproviders.html' to='/campaign/digital-signage-content-providers' />
                            <Redirect from='/videoproductionedinburgh.html' to='/campaign/video-production-edinburgh' />
                            <Redirect from='/animationcompaniesedinburgh.html' to='/campaign/animation-companies-edinburgh' />
                            <Redirect from='/webdesignedinburgh.html' to='/campaign/web-design-edinburgh' />
                            <Redirect from='/landcreativeagencyedinburgh.html' to='/campaign/creative-agency-edinburgh' />
                            <Redirect from='/corporateeventproduction.html' to='/campaign/corporate-event-production' />
                            <Redirect from='/eventproductioncompanies.html' to='/campaign/event-production-companies' />
                            <Redirect from='/eventproductionedinburgh.html' to='/campaign/event-production-edinburgh' />
                            <Redirect from='/productioncompaniesedinburgh.html' to='/campaign/production-companies-edinburgh' />
                            <Redirect from='/sanfranciscoeventproductioncompanies.html' to='/campaign/san-francisco-event-production-companies' />
                            <Redirect from='/sanfranciscoproductioncompanies.html' to='/campaign/san-francisco-production-companies' />
                            {/* blog posts */}
                            <Redirect from='/posts/post-corporateeventdesigntoimpressyourguests.html' to='/posts/corporate-event-design-to-impress-your-guests' />
                            <Redirect from='/post-corporateeventdesigntoimpressyourguests.html' to='/posts/corporate-event-design-to-impress-your-guests' />
                            <Redirect from='/posts/latestnews-edinburghrugbypartnership.html' to='/posts/2020-productions-renews-edinburgh-rugby-partnership' />
                            <Redirect from='/latestnews-edinburghrugbypartnership.html' to='/posts/2020-productions-renews-edinburgh-rugby-partnership' />
                            <Redirect from='/posts/latestnew-creativedigestappbrandintegration.html' to='/posts/why-your-app-should-offer-brand-integration' />
                            <Redirect from='/latestnew-creativedigestappbrandintegration.html' to='/posts/why-your-app-should-offer-brand-integration' />
                            <Redirect from='/posts/latestnews-ioic2019win.html' to='/posts/glitz-glam-and-double-glory' />
                            <Redirect from='/latestnews-ioic2019win.html' to='/posts/glitz-glam-and-double-glory' />
                            <Redirect from='/posts/latestnews-scosponsorship.html' to='/posts/conducting-creative-communications-with-sco' />
                            <Redirect from='/latestnews-scosponsorship.html' to='/posts/conducting-creative-communications-with-sco' />
                            <Redirect from='/posts/latestnews-aguidetoeventregistration.html' to='/posts/a-guide-to-event-registration' />
                            <Redirect from='/latestnews-aguidetoeventregistration.html' to='/posts/a-guide-to-event-registration' />
                            <Redirect from='/posts/post-theadvantagesofacorporateexplainervideo.html' to='/posts/the-advantages-of-a-corporate-explainer-video' />
                            <Redirect from='/post-theadvantagesofacorporateexplainervideo.html' to='/posts/the-advantages-of-a-corporate-explainer-video' />
                            <Redirect from='/posts/post-theimportanceofuserexperienceforbrands.html' to='/posts/the-importance-of-user-experience-for-brands' />
                            <Redirect from='/post-theimportanceofuserexperienceforbrands.html' to='/posts/the-importance-of-user-experience-for-brands' />
                            <Redirect from='/posts/post-thebenefitsofvideomarketing.html' to='/posts/the-benefits-of-video-marketing' />
                            <Redirect from='/post-thebenefitsofvideomarketing.html' to='/posts/the-benefits-of-video-marketing' />
                            <Redirect from='/posts/latestnews-iipsilver.html' to='/posts/2020-productions-achieves-investors-in-people-silver-accreditation' />
                            <Redirect from='/latestnews-iipsilver.html' to='/posts/2020-productions-achieves-investors-in-people-silver-accreditation' />
                            <Redirect from='/posts/news-eawards2019winners.html' to='/posts/e-awards-2019-winners' />
                            <Redirect from='/news-eawards2019winners.html' to='/posts/e-awards-2019-winners' />
                            <Redirect from='/posts/news-eawards2019.html' to='/posts/e-awards-2019-winners' />
                            <Redirect from='/news-eawards2019.html' to='/posts/e-awards-2019-winners' />
                            <Redirect from='/posts/thinktank-howavirtualrealityexperiencecanenhanceyourevent.html' to='/posts/how-a-virtual-reality-experience-can-enhance-your-event' />
                            <Redirect from='/thinktank-howavirtualrealityexperiencecanenhanceyourevent.html' to='/posts/how-a-virtual-reality-experience-can-enhance-your-event' />
                            <Redirect from='/posts/latestnews-bbginternalcomms.html' to='/posts/why-good-internal-communication-is-becoming-increasingly-important' />
                            <Redirect from='/latestnews-bbginternalcomms.html' to='/posts/why-good-internal-communication-is-becoming-increasingly-important' />
                            <Redirect from='/posts/thinktank-abehindthesceneslookatvideoproductionandediting.html' to='/posts/a-behind-the-scenes-look-at-video-production-and-editing' />
                            <Redirect from='/thinktank-abehindthesceneslookatvideoproductionandediting.html' to='/posts/a-behind-the-scenes-look-at-video-production-and-editing' />
                            <Redirect from='/posts/thinktank-thetopchallengesfacingsmallbusinesses.html' to='/posts/the-top-challenges-facing-small-businesses' />
                            <Redirect from='/thinktank-thetopchallengesfacingsmallbusinesses.html' to='/posts/the-top-challenges-facing-small-businesses' />
                            <Redirect from='/posts/thinktank-5topwaystocommunicatewithyouremployees.html' to='/posts/5-top-ways-to-communicate-with-your-employees' />
                            <Redirect from='/thinktank-5topwaystocommunicatewithyouremployees.html' to='/posts/5-top-ways-to-communicate-with-your-employees' />
                            <Redirect from='/posts/latestnews-installationinternational.html' to='/posts/the-dos-and-donts-of-digital-signage-design' />
                            <Redirect from='/latestnews-installationinternational.html' to='/posts/the-dos-and-donts-of-digital-signage-design' />
                            <Redirect from='/posts/post-howyourbusinesscanuseanexplainervideo.html' to='/posts/how-your-business-can-use-an-explainer-video' />
                            <Redirect from='/post-howyourbusinesscanuseanexplainervideo.html' to='/posts/how-your-business-can-use-an-explainer-video' />
                            <Redirect from='/posts/thinktank-whypro-bonoworkcanbegoodforyourbusiness.html' to='/posts/why-probono-work-can-be-good-for-your-business' />
                            <Redirect from='/thinktank-whypro-bonoworkcanbegoodforyourbusiness.html' to='/posts/why-probono-work-can-be-good-for-your-business' />
                            <Redirect from='/posts/latestnews-thebestdesignfordigitalsignage.html' to='/posts/the-best-design-for-digital-signage' />
                            <Redirect from='/latestnews-thebestdesignfordigitalsignage.html' to='/posts/the-best-design-for-digital-signage' />
                            <Redirect from='/posts/latestnews-improveyourinternalcommunications.html' to='/posts/4-simple-ways-to-improve-your-internal-communications' />
                            <Redirect from='/latestnews-improveyourinternalcommunications.html' to='/posts/4-simple-ways-to-improve-your-internal-communications' />
                            <Redirect from='/posts/thinktank-toptipsforcapturinggreatvideo.html' to='/posts/top-tips-for-capturing-great-video' />
                            <Redirect from='/thinktank-toptipsforcapturinggreatvideo.html' to='/posts/top-tips-for-capturing-great-video' />
                            <Redirect from='/posts/post-digitaltrendsin2019.html' to='/posts/digital-trends-in-2019' />
                            <Redirect from='/post-digitaltrendsin2019.html' to='/posts/digital-trends-in-2019' />
                            <Redirect from='/posts/latestnews-creativedigest.html' to='/posts/how-to-use-visual-communication-to-connect-with-your-customer-base' />
                            <Redirect from='/latestnews-creativedigest.html' to='/posts/how-to-use-visual-communication-to-connect-with-your-customer-base' />
                            <Redirect from='/posts/post-bestnewtech2019forevents.html' to='/posts/best-new-tech-2019-for-events' />
                            <Redirect from='/post-bestnewtech2019forevents.html' to='/posts/best-new-tech-2019-for-events' />
                            <Redirect from='/posts/latestnews-eventit2019.html' to='/posts/registration-opens-for-eventit-2019' />
                            <Redirect from='/latestnews-eventit2019.html' to='/posts/registration-opens-for-eventit-2019' />
                            <Redirect from='/posts/post-guidetodigitalsignagecontentcreation.html' to='/posts/guide-to-digital-signage-content-creation' />
                            <Redirect from='/post-guidetodigitalsignagecontentcreation.html' to='/posts/guide-to-digital-signage-content-creation' />
                            <Redirect from='/posts/post-howtodevelopavideoproductionbrief.html' to='/posts/how-to-develop-a-video-production-brief' />
                            <Redirect from='/post-howtodevelopavideoproductionbrief.html' to='/posts/how-to-develop-a-video-production-brief' />
                            <Redirect from='/posts/latestnews-futurescotarticle.html' to='/posts/how-to-nail-the-creative-side-of-corporate-video-production' />
                            <Redirect from='/latestnews-futurescotarticle.html' to='/posts/how-to-nail-the-creative-side-of-corporate-video-production' />
                            <Redirect from='/posts/post-eventplanningchecklist.html' to='/posts/event-planning-checklist' />
                            <Redirect from='/post-eventplanningchecklist.html' to='/posts/event-planning-checklist' />
                            {/* <Redirect from='/posts/' to='/posts/how-to-get-the-most-out-of-your-digital-signage' />  */}
                            {/* <Redirect from='' to='/posts/how-to-get-the-most-out-of-your-digital-signage' />                         */}
                            <Redirect from='/posts/post-effectiveeventvideoproduction.html' to='/posts/effective-event-video-production' />
                            <Redirect from='/post-effectiveeventvideoproduction.html' to='/posts/effective-event-video-production' />
                            <Redirect from='/posts/thinktank-effectiveeventplanningandmanaging.html' to='/posts/effective-event-planning-and-managing' />
                            <Redirect from='/thinktank-effectiveeventplanningandmanaging.html' to='/posts/effective-event-planning-and-managing' />
                            <Redirect from='/posts/latestnews-creativeandculturalskills.html' to='/posts/creative-and-cultural-skills' />
                            <Redirect from='/latestnews-creativeandculturalskills.html' to='/posts/creative-and-cultural-skills' />
                            <Redirect from='/posts/blogpost-corporateeventsproduction.html' to='/posts/how-to-nail-your-corporate-events-production' />
                            <Redirect from='/blogpost-corporateeventsproduction.html' to='/posts/how-to-nail-your-corporate-events-production' />
                            <Redirect from='/posts/blogpost-onlineeventregistration.html' to='/posts/why-online-event-registration-is-a-must' />
                            <Redirect from='/blogpost-onlineeventregistration.html' to='/posts/why-online-event-registration-is-a-must' />
                            <Redirect from='/posts/thinktank-employeeengagement.html' to='/posts/the-business-benefits-of-an-engaged-workforce' />
                            <Redirect from='/thinktank-employeeengagement.html' to='/posts/the-business-benefits-of-an-engaged-workforce' />
                            <Redirect from='/posts/post-topeventtechtoenhanceyourevent.html' to='/posts/top-event-tech-to-enhance-your-event' />
                            <Redirect from='/post-topeventtechtoenhanceyourevent.html' to='/posts/top-event-tech-to-enhance-your-event' />
                            <Redirect from='/posts/thinktank-digitaltrendsthatbusinessescanembrace.html' to='/posts/digital-trends-every-business-can-embrace' />
                            <Redirect from='/thinktank-digitaltrendsthatbusinessescanembrace.html' to='/posts/digital-trends-every-business-can-embrace' />
                            <Redirect from='/posts/thinktank-howtoengageyouremployeeswithcreativeevents.html' to='/posts/how-to-engage-your-employees-with-creative-events' />
                            <Redirect from='/thinktank-howtoengageyouremployeeswithcreativeevents.html' to='/posts/how-to-engage-your-employees-with-creative-events' />
                            <Redirect from='/posts/thinktank-bestinternalcommunicationtools.html' to='/posts/best-internal-communication-tools' />
                            <Redirect from='/thinktank-bestinternalcommunicationtools.html' to='/posts/best-internal-communication-tools' />
                            <Redirect from='/posts/news-listenandrespond-youcantgowrong.html' to='/posts/listen-and-respond-you-cant-go-wrong' />
                            <Redirect from='/news-listenandrespond-youcantgowrong.html' to='/posts/listen-and-respond-you-cant-go-wrong' />
                            <Redirect from='/posts/thinktank-howtowriteaninternalcommunicationsstrategy.html' to='/posts/how-to-write-an-internal-communications-strategy' />
                            <Redirect from='/thinktank-howtowriteaninternalcommunicationsstrategy.html' to='/posts/how-to-write-an-internal-communications-strategy' />
                            <Redirect from='/posts/news-carnegieukandmichaelsheen.html' to='/posts/2020-productions-support-carnegie-uk-and-michael-sheen-for-affordable-credit-film' />
                            <Redirect from='/news-carnegieukandmichaelsheen.html' to='/posts/2020-productions-support-carnegie-uk-and-michael-sheen-for-affordable-credit-film' />
                            <Redirect from='/posts/news-20thyearofproducingcipdconference.html' to='/posts/2020-celebrates-20th-cipd-conference' />
                            <Redirect from='/news-20thyearofproducingcipdconference.html' to='/posts/2020-celebrates-20th-cipd-conference' />



                            {/* end redirects */}


                            {/* Page routeing */}
                            <Route exact path="/" component={WhatWeDo} />
                            <Route path="/home" component={WhatWeDo} />
                            <Route path="/what-we-do" component={WhatWeDo} />
                                <Route path="/our-services/event-production" component={WhatWeDoEvents} />
                                <Route path="/our-services/film" component={WhatWeDoFilm} />
                                <Route path="/our-services/design" component={WhatWeDoDesign} />
                                <Route path="/our-services/motion" component={WhatWeDoMotion} />
                            <Route path="/projects" component={Projects} />
                            <Route path="/about" component={About} />
                            <Route path="/contact" component={Contact} />
                            <Route path="/site-map" component={Sitemap} />
                            <Route path="/accessibility" component={Accessibility} />
                            <Route path="/privacy-policy" component={Privacy} />
                            <Route path="/careers" component={Careers} />
                            <Route path="/linktree" component={LinkTree} />
                            <Route path="/usa-payments" component={UsaPayments} />

                            <Route path="/campaign/production-companies-edinburgh" component={ProductionCompaniesEdinburgh} />
                            <Route path="/campaign/digital-signage-content-providers" component={DigitalSignageContentProviders} />
                            <Route path="/campaign/creative-agency-edinburgh" component={CreativeAgencyEdinburgh} />
                            <Route path="/campaign/corporate-event-production" component={CorporateEventProduction} />
                            <Route path="/campaign/animation-companies-edinburgh" component={AnimationCompaniesEdinburgh} />
                            <Route path="/campaign/event-production-companies" component={EventProductionCompanies} />
                            <Route path="/campaign/event-production-edinburgh" component={EventProductionEdinburgh} />
                            <Route path="/campaign/san-francisco-event-production-companies" component={SFEventProductionCompanies} />
                            <Route path="/campaign/san-francisco-production-companies" component={SFProductionCompanies} />
                            <Route path="/campaign/video-production-edinburgh" component={VideoProductionEdinburgh} />
                            <Route path="/campaign/digital-signage-solutions-companies" component={DigitalSignageSolutionsCompanies} />
                            <Route path="/sitemap.xml" component={SitemapXML}/>
                            <Route component={PageNotFound} />

                        </Switch>

                    <Footer></Footer>
                    </Suspense>
                </div>

                <div id="accessibilitymenu" style={{display: 'none'}}>
                    <ul>
                        <li><a href="/" accessKey="h">What We Do</a></li>
                        <li><a href="/projects" accessKey="1">Projects</a></li>
                        <li><a href="/about" accessKey="2">About</a></li>
                        <li><a href="/contact" accessKey="4">Contact</a></li>
                        <li><a href="/our-services/event-production" accessKey="5">Services - Events</a></li>
                        <li><a href="/our-services/film" accessKey="6">Services - Film</a></li>
                        <li><a href="/our-services/design" accessKey="7">Services - Design</a></li>
                        <li><a href="/our-services/motion" accessKey="9">Services - Motion</a></li>
                        <li><a href="/site-map" accessKey="s">Sitemap</a></li>
                        <li><a href="/privacy-policy" accessKey="p">Privacy</a></li>
                        <li><a href="/accessibility" accessKey="a">Accessibility</a></li>
                        <li><a href="/linktree" accessKey="t">LinkTree</a></li>
                        <li><a href="#content" accessKey="c">Skip to main content</a></li>
                    </ul>
                </div>


                <CookieConsent
                        location="bottom"
                        cookieName="consentCookie"
                        style={{
                            background: "#1F2536",
                            opacity: ".9",
                            animation: "1.5s fadeInText",
                            animationFillMode: "forwards",
                            zIndex: "99999"
                        }}
                        buttonStyle={{
                            backgroundColor: "#24CFD2",
                            color: "#FFF",
                            fontWeight: "bold",
                            textTransform: "uppercase",
                            fontSize: "14px",
                            padding: "10px 20px",
                            borderRadius: "4px"
                        }}
                        expires={365}
                        // acceptOnScroll={true}
                        // acceptOnScrollPercentage={0}
                        // onAccept={ ({ acceptedByScrolling }) => {
                        //     if (acceptedByScrolling) {
                        //         this.checkCookieCompliance();
                        //         // triggered if user scrolls past threshold
                        //     //   this.runGA();
                        //         alert("Accept was triggered by scrolling");
                        //         ReactGA.initialize('UA-7464175-1');
                        //         ReactGA.pageview(window.location.pathname + window.location.search);
                        //     } else {
                        //         this.checkCookieCompliance();
                        //         // this.runGA();
                        //         alert("Accept was triggered by clicking the Accept button");
                        //         ReactGA.initialize('UA-7464175-1');
                        //         ReactGA.pageview(window.location.pathname + window.location.search);
                        //     }
                        // }}
                        onAccept={ () => {
                            this.checkCookieCompliance();
                            this.onAccept();
                        }}
                        enableDeclineButton={true}
                        declineButtonClasses='declineButton'
                        declineButtonText="Decline Cookies"
                        declineButtonStyle={{
                            backgroundColor: "#1F2536",
                            fontWeight: "bold",
                            textTransform: "uppercase",
                            fontSize: "14px"
                        }}
                        onDecline={() => {
                            this.checkCookieCompliance();
                            this.onDelete();
                        }}
                    >
                       <div className="cookieInfo">This website use cookies to ensure you get the best experience. <a href="/privacy-policy" title="Read more in our Privacy Policy" style={{color: "#FFF",textTransform: "uppercase",fontSize: "14px"}}>Read&nbsp;More</a></div>
                    </CookieConsent>


            </Router>
        );
    }
}
export default App;
